import { FC, PropsWithChildren } from 'react';

import { Paper } from '@mui/material';

import { Styles } from 'types/theme.type';

const styles: Styles = {
  loginFormBox: {
    p: 3,
    maxWidth: 'sm',
    textAlign: 'center',
    width: { xs: 'auto', sm: '584px' },
    m: 'auto',
  },
};

const LoginFormContainerWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Paper elevation={16} square sx={styles.loginFormBox}>
      {children}
    </Paper>
  );
};

export default LoginFormContainerWrapper;
