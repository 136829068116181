import { ROUTES } from './routes';

export const GRANT_TYPE = {
  LOGIN: 'password',
  TOKEN: 'refresh_token',
};

export const AUTH_HEADERS = {
  'content-type': 'application/x-www-form-urlencoded',
};

export const USE_AUTH_RESTORE_CODES = false;

export const LOGIN_PAGE_URL = `${process.env.PUBLIC_URL}${ROUTES.auth.login}`;

export const ERR_MSG =
  'Виникли труднощі з входом у систему. Спробуйте ще раз, а в разі повторної відмови зверніться в Підтримку.';
