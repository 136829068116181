import { useCallback } from 'react';

import { Box, Button } from '@mui/material';

import EnterCodeForm from 'components/EnterCodeForm';
import ModalContentTitle from 'components/ModalContentTitle';
import { USE_AUTH_RESTORE_CODES } from 'constants/auth';
import { Styles } from 'types/theme.type';

const styles: Styles = {
  enterCodeBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    minWidth: '300px',
    pb: 2,
  },
  restoreCodeBtn: { textTransform: 'none', fontSize: '16px' },
};

const FormVariant = {
  otp: 'otp',
  restoreCode: 'restoreCode',
} as const;

type Props = {
  autoFocus?: boolean;
  disabled?: boolean;
  variant?: (typeof FormVariant)[keyof typeof FormVariant];
  onSubmit?: (code: string) => void;
  onChangeVariantClick?: () => void;
};

const TwoFactorAuthEnterCodeForm = ({
  autoFocus = false,
  disabled = false,
  variant = FormVariant.otp,
  onSubmit = () => {},
  onChangeVariantClick = () => {},
}: Props) => {
  const formSubmitHandler = useCallback(({ code }: { code: string }) => {
    onSubmit(code);
  }, []);

  return (
    <Box sx={styles.enterCodeBox}>
      <ModalContentTitle>Двофакторна автентифікація</ModalContentTitle>
      <EnterCodeForm
        autoFocus={autoFocus}
        disabled={disabled}
        textFieldLabel="Код - 6 цифр"
        submitButtonText="Автентифікувати"
        onSubmitClick={formSubmitHandler}
        descriptionText={
          variant === FormVariant.restoreCode
            ? 'Введіть резервний код '
            : 'Введіть код з Вашого застосунку для 2FA автентифікації'
        }
      />

      {USE_AUTH_RESTORE_CODES && (
        <Button
          variant="text"
          color="primary"
          sx={styles.restoreCodeBtn}
          onClick={onChangeVariantClick}
        >
          {variant === FormVariant.restoreCode
            ? 'Використати звичайні коди'
            : 'Використати резервні коди'}
        </Button>
      )}
    </Box>
  );
};

export default TwoFactorAuthEnterCodeForm;
