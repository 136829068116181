import { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
// eslint-disable-next-line import/no-unresolved
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';

import { APP_BE_URL } from 'constants/appBeUrl';
import { AuthLoginProps, LoginState, MfaConfig } from 'types/auth.type';

const AUTH_BASE_URL = `${APP_BE_URL}/api/v1/auth`;
const AUTH_LOGIN_URL = `${AUTH_BASE_URL}/login`;
const AUTH_MFA_STATUS = `${AUTH_BASE_URL}/mfa/status`;
const AUTH_MFA_VERIFY = `${AUTH_BASE_URL}/mfa/verify`;
const AUTH_MFA_CREATE = `${AUTH_BASE_URL}/mfa/create`;
const AUTH_MFA_RECOVERY_CODES = `${AUTH_BASE_URL}/mfa/recovery-codes`;
const AUTH_MFA_DELETE = `${AUTH_BASE_URL}/mfa/delete`;
const AUTH_LOGOUT_URL = `${AUTH_BASE_URL}/logout`;

const BRAND_NAME = `${process.env.REACT_APP_BRAND_NAME}` || window.location.hostname;

type MfaStatusType = {
  sessionVerified?: boolean;
  isMfaAuthConfirmed?: boolean;
  isMfaAuthEnforced?: boolean;
  isMfaAuthConfigured?: boolean;
};

export const endpoints = (
  builder: EndpointBuilder<
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
    string,
    string
  >
) => ({
  signIn: builder.mutation<
    Pick<LoginState, 'isMfaAuthEnforced' | 'isMfaAuthConfigured'>,
    AuthLoginProps
  >({
    queryFn: async ({ username, password }, _queryApi, _extraOptions, baseQuery) => {
      const payload = {
        username,
        password,
      };
      const loginResponse = await baseQuery({
        url: AUTH_LOGIN_URL,
        method: 'POST',
        body: payload,
      });
      if (loginResponse.error) {
        return {
          error: loginResponse.error,
        };
      }

      const otpConfigResponse = await baseQuery({
        url: AUTH_MFA_STATUS,
        method: 'GET',
      });
      if (otpConfigResponse.error) {
        return {
          error: otpConfigResponse.error,
        };
      }

      const otpConfigData = otpConfigResponse.data as { mfaEnforced: boolean; created: boolean };
      return {
        data: {
          isMfaAuthEnforced: otpConfigData?.mfaEnforced,
          isMfaAuthConfigured: otpConfigData?.created,
        },
      };
    },
  }),
  signOut: builder.mutation<void, void>({
    query: () => {
      return {
        url: AUTH_LOGOUT_URL,
        method: 'POST',
      };
    },
  }),
  getMfaStatus: builder.query<MfaStatusType, void>({
    query: () => AUTH_MFA_STATUS,
    transformResponse({ mfaEnforced, created, sessionVerified, confirmed }) {
      return {
        sessionVerified,
        isMfaAuthConfirmed: confirmed,
        isMfaAuthEnforced: mfaEnforced,
        isMfaAuthConfigured: created,
      };
    },
    providesTags: ['authMfaStatus'],
  }),
  getMfaRecoveryCodes: builder.query<{ codes: string[] }, void>({
    query: () => AUTH_MFA_RECOVERY_CODES,
  }),
  verifyMfaCode: builder.mutation<void, { code: string }>({
    query: ({ code }) => ({
      url: AUTH_MFA_VERIFY,
      method: 'POST',
      body: { code },
    }),
    invalidatesTags: ['authMfaStatus', 'currentUser'],
  }),
  createMfa: builder.mutation<MfaConfig, void>({
    query: () => ({
      url: AUTH_MFA_CREATE,
      method: 'POST',
      body: {},
    }),
    transformResponse({ otpAuthUrl }) {
      const urlParams = new URLSearchParams(otpAuthUrl.split('?')[1]);
      const newIssuer = urlParams.get('issuer') ? '' : `&issuer=${BRAND_NAME}`;
      return {
        otpAuthUrl: `${otpAuthUrl}${newIssuer}`,
        otpAuthSecret: urlParams.get('secret') || '',
      };
    },
    invalidatesTags: ['authMfaStatus'],
  }),
  deleteMfa: builder.mutation<void, void>({
    query: () => ({
      url: AUTH_MFA_DELETE,
      method: 'DELETE',
    }),
    invalidatesTags: ['authMfaStatus'],
  }),
});
