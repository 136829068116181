import { FC } from 'react';

import {
  Box,
  CircularProgress,
  CircularProgressProps,
  BoxProps,
  SxProps,
  Theme,
} from '@mui/material';

import { HEADER_HEIGHT } from 'constants/ui';
import { Styles } from 'types/theme.type';

interface Props extends BoxProps {
  color?: CircularProgressProps['color'];
}

const styles: Styles = {
  loading: {
    width: '100%',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const Loading: FC<Props> = ({ color, sx, height, ...rest }) => (
  <Box sx={[styles.loading, sx, { height }] as SxProps<Theme>} {...rest}>
    <CircularProgress color={color} />
  </Box>
);
