import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

import { SUCCESS, ERROR, WARNING, GREY } from './palette';

const GlobalStyles = (): React.ReactElement => {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={(theme) => ({
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          width: '100%',
          height: '100%',
        },
        'ul, li': {
          margin: 0,
          padding: 0,
          listStyle: 'none',
        },
        a: { textDecoration: 'none' },
        ':root': {
          '--mui-palette-common-black': theme.palette.common.black,
          '--mui-palette-action-active': theme.palette.action.active,
          '--mui-palette-action-hover': theme.palette.action.hover,
          '--mui-palette-primary-main': theme.palette.primary.main,
          '--mui-palette-secondary-main': theme.palette.secondary.main,
          '--mui-palette-error-main': theme.palette.error.main,
          '--mui-palette-warning-light': theme.palette.warning.light,
          '--mui-palette-warning-dark': theme.palette.warning.dark,
          '--mui-palette-success-main': theme.palette.success.main,
          '--mui-palette-info-lighter': theme.palette.info.lighter,
          '--mui-palette-info-main': theme.palette.info.main,
          '--mui-palette-text-primary': theme.palette.text.primary,
          '--mui-palette-text-disabled': theme.palette.text.disabled,
          '--mui-palette-text-secondary': theme.palette.text.secondary,
          '--mui-palette-background-default': theme.palette.background.default,
          '--mui-palette-background-paper': theme.palette.background.paper,
          '--mui-palette-background-elevation1': theme.palette.background.elevation1,
          '--mui-palette-primary-contrastText': theme.palette.primary.contrastText,
          '--mui-palette-divider': theme.palette.divider,
          '--mui-shadows-1': theme.shadows[1],
          '--mui-shadows-3': theme.shadows[3],
          '--mui-gap': '8px',
          '--mui-shape-border-radius': `${theme.shape.borderRadius}px`,
          ...Object.entries(theme.palette.grey).reduce(
            (vars, [strength, color]) => ({
              ...vars,
              [`--mui-palette-grey-${strength}`]: color,
            }),
            {}
          ),
        },
        // rewrite react-toaster styles
        '.success-toaster': {
          backgroundColor: SUCCESS.main,
          color: 'white',
          fontWeight: 600,
          fontSize: '12px',
        },
        '.error-toaster': {
          backgroundColor: ERROR.main,
          color: 'white',
          fontWeight: 600,
          fontSize: '12px',
        },
        '.warning-toaster': {
          backgroundColor: WARNING.main,
          color: 'white',
          fontWeight: 600,
          fontSize: '12px',
        },
        '.Toastify__toast': {
          borderRadius: '32px 0 0 32px',
          minHeight: 'auto',
          maxWidth: '500px',
        },
        '.Toastify__close-button': {
          color: 'white',
          opacity: 0.9,
        },
        // react-color library
        '.github-picker-marker': {
          gap: '4px',
          '& span': {
            border: `1px solid ${GREY[500]}`,
          },
        },
        // react-easy-sorting global classes
        '.dragged-list': {
          backgroundColor: theme.palette.grey[100],
        },
        '.dragged-item__active': {
          cursor: 'grabbing',
          boxShadow:
            '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20)',
        },
        '.text-transform-none': {
          textTransform: 'none',
          '&.MuiButton-root': {
            textTransform: 'none',
          },
        },
      })}
    />
  );

  return inputGlobalStyles;
};

export default GlobalStyles;
