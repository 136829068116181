export const styles = {
  appConnectContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    minWidth: '300px',
  },
  qrCodeBox: {
    maxWidth: '150px',
    margin: '0 auto',
    '& svg': {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
      margin: '0 auto',
    },
  },

  textCodeBox: {
    fontWeight: 600,
    fontSize: '14px',
  },
};
