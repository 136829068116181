import { createApi } from '@reduxjs/toolkit/query/react';

import { getConfiguredBaseQuery } from 'api/getConfiguredBaseQuery';
import { APP_BE_URL } from 'constants/appBeUrl';

export const API_BASE_URL = `${APP_BE_URL}/api/v1`;

export const api = createApi({
  reducerPath: 'splitApi',
  baseQuery: getConfiguredBaseQuery(API_BASE_URL),
  tagTypes: ['authMfaStatus', 'currentUser'],
  endpoints: () => ({}),
});
