import { FC, useCallback } from 'react';

import { Form, Formik, FormikConfig } from 'formik';
import * as yup from 'yup';

import { Box, Button, Typography } from '@mui/material';

import FormikTextField from 'components/FormikTextField';
import { Styles } from 'types/theme.type';

const styles: Styles = {
  enterCodeBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    minWidth: '300px',
  },
  verificationTextField: { width: '330px', pb: 0, minHeight: 68 },
  submitBtn: { width: 280 },
};

const textFieldProps = { min: 0, max: 999999, maxLength: 6 };

type EnterCodeFormType = { code: string };

const formInitialValues: EnterCodeFormType = { code: '' };

const formValidationSchema = yup.object().shape({
  code: yup.string().required("Обов'язкове поле"),
});

const EnterCodeForm: FC<{
  submitButtonText: string;
  textFieldLabel: string;
  disabled?: boolean;
  autoFocus?: boolean;
  descriptionText?: string;

  onSubmitClick?: (values: EnterCodeFormType) => void;
}> = ({
  submitButtonText,
  textFieldLabel,
  disabled = false,
  autoFocus = false,
  descriptionText = '',

  onSubmitClick = () => {},
}) => {
  const formSubmitHandler: FormikConfig<EnterCodeFormType>['onSubmit'] = useCallback(
    ({ code }, { setSubmitting }) => {
      onSubmitClick({ code });
      setSubmitting(false);
    },
    []
  );

  return (
    <Box sx={styles.enterCodeBox}>
      {descriptionText && <Typography pb={2}>{descriptionText}</Typography>}
      <Formik
        initialValues={formInitialValues}
        onSubmit={formSubmitHandler}
        validationSchema={formValidationSchema}
        validateOnBlur={false}
      >
        {({ values, handleChange, submitForm }) => (
          <Form>
            <FormikTextField
              sx={styles.verificationTextField}
              id="code"
              name="code"
              label={textFieldLabel}
              variant="outlined"
              type="number"
              size="small"
              value={values.code}
              onChange={handleChange}
              inputProps={textFieldProps}
              autoFocus={autoFocus}
            />
            <Button
              sx={styles.submitBtn}
              color="primary"
              variant="contained"
              size="medium"
              disabled={disabled}
              onClick={submitForm}
            >
              {submitButtonText}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EnterCodeForm;
