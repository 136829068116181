import { FC } from 'react';

import dayjs from 'dayjs';

import { Box, Typography } from '@mui/material';

import { DATE_TIME_FORMAT } from 'constants/date';
import { ErrorData } from 'types/api.type';

type ErrorState = {
  error: ErrorData | null;
};

const LoginLimitedError: FC<ErrorState> = ({ error }) => {
  const extra = error?.extra ?? {};
  const failedAttempts = extra?.failedAttempts;
  const retryStringDate = extra?.retryAfter;

  const isRetryStringDateValid = retryStringDate && typeof retryStringDate === 'string';
  const retryAfter = isRetryStringDateValid && dayjs(retryStringDate).format(DATE_TIME_FORMAT);

  return (
    <Box>
      {!!failedAttempts && (
        <Typography color="error">{`Ви здійснили ${failedAttempts} невдалих спроб.`}</Typography>
      )}
      {!!retryAfter && (
        <Typography color="error">{`Наступний вхід можливий не раніше ніж ${retryAfter}`}</Typography>
      )}
    </Box>
  );
};

export default LoginLimitedError;
