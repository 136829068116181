import { FC, useCallback } from 'react';

import { Paper, Box, Divider, SxProps, Theme } from '@mui/material';

import EnterCodeForm from 'components/EnterCodeForm';

import ApplicationConnect from './components/ApplicationConnect';

const styles: Record<string, SxProps<Theme>> = {
  mainBox: {
    maxWidth: 'sm',
    textAlign: 'center',
    '@media print': {
      '.no-print': {
        display: 'none !important',
      },
    },
  },
  contentBox: {
    p: 1,
  },
};

type Props = {
  otpAuthUrl?: string;
  otpAuthSecret?: string;

  onOtpSubmit?: (code: string) => void;
};

const TwoFactorAuthSetup: FC<Props> = ({
  otpAuthUrl = '',
  otpAuthSecret = '',
  onOtpSubmit = () => {},
}) => {
  const submitClickHandler = useCallback(({ code }: { code: string }) => onOtpSubmit(code), []);

  return (
    <Box sx={styles.mainBox}>
      <Paper sx={styles.contentBox} elevation={0}>
        <ApplicationConnect otpAuthUrl={otpAuthUrl} otpAuthSecret={otpAuthSecret} />
        <Box pb={4}>
          <Divider />
        </Box>
        <EnterCodeForm
          textFieldLabel="Код - 6 цифр"
          submitButtonText="Підтвердити"
          onSubmitClick={submitClickHandler}
          descriptionText="Введіть код з Вашого застосунку для двофакторної автентифікації, щоб переконатися, що все працює"
        />
      </Paper>
    </Box>
  );
};

export default TwoFactorAuthSetup;
