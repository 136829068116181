import { FC, forwardRef, ChangeEventHandler } from 'react';

import { useField } from 'formik';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

import CloseIcon from '@mui/icons-material/Close';
import { FormHelperText, IconButton, InputAdornment, SxProps, TextField } from '@mui/material';

import { FormikTextFieldProps } from './types';

const InputEndAdornment = (resetValue: () => void) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={resetValue} aria-label="delete" size="small">
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </InputAdornment>
  );
};

interface NumericFormatCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  format: string;
}

const NumericFormatCustom = forwardRef<PatternFormatProps, NumericFormatCustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <PatternFormat
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.formattedValue,
            },
          });
        }}
        valueIsNumericString={false}
        {...other}
      />
    );
  }
);

const styles = {
  textField: {
    '& .MuiFormLabel-root .text-field-label-focused': { display: 'none' },
    '& .MuiInputBase-root': {
      '& .text-field-label-focused': { display: 'inline' },
      '& .text-field-label-default': { display: 'none' },
    },
    '& .MuiInputLabel-shrink': {
      '& .text-field-label-focused': { display: 'inline' },
      '& .text-field-label-default': { display: 'none' },
    },
  },
  helperText: { ml: '14px' },
};

const FormikTextField: FC<FormikTextFieldProps> = ({
  name,
  label,
  focusedLabel,
  reset,
  sx,
  patternFormatProps,
  onChange,
  helperText,
  ...props
}) => {
  const [{ onChange: fieldOnChange, ...field }, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const errorText = meta.error && meta.touched ? meta.error : '';

  const fieldValue = meta.value === undefined ? '' : meta.value;

  const handleReset = () => setValue('');

  const inputProps = {
    ...(patternFormatProps && {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inputComponent: NumericFormatCustom as any,
      inputProps: patternFormatProps,
    }),
    ...props.InputProps,
    endAdornment:
      fieldValue && reset ? InputEndAdornment(handleReset) : props.InputProps?.endAdornment,
  };
  const newLabel = focusedLabel ? (
    <>
      <span className="text-field-label-focused">{focusedLabel}</span>
      <span className="text-field-label-default">{label}</span>
    </>
  ) : (
    label
  );

  const handleTextFieldChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (
    event
  ) => {
    onChange?.(event);
    fieldOnChange(event);
  };

  return (
    <>
      <TextField
        {...field}
        {...props}
        label={newLabel}
        sx={[sx, styles.textField] as SxProps}
        value={fieldValue}
        error={Boolean(errorText)}
        helperText={errorText}
        InputProps={inputProps}
        onChange={handleTextFieldChange}
      />
      {helperText && <FormHelperText sx={styles.helperText}>{helperText}</FormHelperText>}
    </>
  );
};

export default FormikTextField;
