import * as Sentry from '@sentry/react';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import Notification from 'components/Alert';
import { ThemeProvider } from 'contexts/ThemeContext';
import ErrorPage from 'error-page';

import { AuthContextProvider } from './contexts/AuthContext/AuthContextProvider';
import { router } from './router';
import store from './store';

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <ReduxProvider store={store}>
        <ThemeProvider>
          <AuthContextProvider>
            <RouterProvider router={router} />
          </AuthContextProvider>
          <Notification />
        </ThemeProvider>
      </ReduxProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
