import { FC, useCallback } from 'react';

import QRCode from 'react-qr-code';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, IconButton, Skeleton, Typography } from '@mui/material';

import ModalContentTitle from 'components/ModalContentTitle';

import { styles } from './styles';

type Props = {
  otpAuthUrl: string;
  otpAuthSecret: string;
};

const ApplicationConnect: FC<Props> = ({ otpAuthUrl, otpAuthSecret }) => {
  const connectCodeClickHandler = useCallback(() => {
    if (!navigator.clipboard) {
      console.error('No navigation.clipboard! Use HTTPS or localhost domain');
      return;
    }

    navigator.clipboard.writeText(otpAuthSecret);
  }, [otpAuthSecret]);

  return (
    <Box sx={styles.appConnectContainer}>
      <ModalContentTitle>Налаштуйте двофакторну автентифікацію</ModalContentTitle>

      <Typography pb={2}>
        Відкрийте застосунок для двофакторної автентифікації (Google Authenticator, Microsoft
        Authenticator) і відскануйте QR код
      </Typography>

      <Box sx={styles.qrCodeBox}>
        {otpAuthUrl && <QRCode size={150} value={otpAuthUrl} viewBox="0 0 150 150" />}
        {!otpAuthUrl && <Skeleton variant="rectangular" width={150} height={150} />}
      </Box>

      {otpAuthSecret && (
        <Typography pb={5} sx={styles.textCodeBox} onClick={connectCodeClickHandler}>
          {otpAuthSecret}
          <IconButton>
            <ContentCopyIcon />
          </IconButton>
        </Typography>
      )}
      {!otpAuthSecret && (
        <Box display="flex" gap="8px" pb={5}>
          <Skeleton variant="text" width={300} />
          <Skeleton variant="circular" width={25} height={25} />
        </Box>
      )}
    </Box>
  );
};

export default ApplicationConnect;
