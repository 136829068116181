import { useContext, useEffect } from 'react';

import { Loading } from 'components/Loading';
import { AuthContext } from 'contexts/AuthContext';
import { AuthContextType } from 'contexts/AuthContext/types';

const Logout = () => {
  const { logout } = useContext<AuthContextType>(AuthContext);

  useEffect(() => {
    logout();
  }, []);

  return <Loading />;
};

export default Logout;
