import { FC, PropsWithChildren, useCallback, useState, useMemo, useEffect } from 'react';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { router } from 'applications/auth/router';
import { showErrorMessage } from 'components/Alert/utils';
import { PUBLIC_ROUTES } from 'constants/publicRoutes';
import { AuthContext } from 'contexts/AuthContext';
import { AuthContextType } from 'contexts/AuthContext/types';
import { ErrorData } from 'types/api.type';
import { processServerErrorResponse } from 'utils/errors';

import {
  useSignInMutation,
  useSignOutMutation,
  useVerifyMfaCodeMutation,
  useGetCurrentUserQuery,
  useCreateMfaMutation,
  useDeleteMfaMutation,
} from '../../api/authApi';

export const ERR_MSG =
  'Виникли труднощі з входом у систему. Спробуйте ще раз, а в разі повторної відмови зверніться в Підтримку.';

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [loginState, setLoginState] = useState<AuthContextType['loginState']>({
    inProgress: false,
    isAuthenticated: false,
  });

  const [signIn, { isLoading: isSignInLoading }] = useSignInMutation();
  const [signOut, { isLoading: isSignOutLoading }] = useSignOutMutation();
  const [verifyMfaCode, { isLoading: isVerifyMfaCodeLoading }] = useVerifyMfaCodeMutation();
  const { data: currentUser, isLoading: isCurrentUserLoading } = useGetCurrentUserQuery();
  const [triggerCreateMfa] = useCreateMfaMutation();
  const [triggerDeleteMfa] = useDeleteMfaMutation();

  useEffect(() => {
    if (currentUser && loginState && !isCurrentUserLoading) {
      setLoginState({
        ...loginState,
        isAuthenticated: true,
      });
    }
  }, [currentUser, isCurrentUserLoading]);

  const deleteMfa = useCallback(async () => {
    await triggerDeleteMfa().unwrap();
  }, [triggerDeleteMfa]);

  const createMfa = useCallback(() => {
    return triggerCreateMfa().unwrap();
  }, [triggerCreateMfa]);

  const logout = useCallback(async () => {
    try {
      await signOut().unwrap();
      setLoginState({
        isAuthenticated: false,
        inProgress: false,
        error: null,
      });
    } catch (error) {
      processServerErrorResponse(error);
      setLoginState({
        isAuthenticated: false,
        inProgress: false,
        error: (error as FetchBaseQueryError)?.data as ErrorData,
      });
    }
    router.navigate(PUBLIC_ROUTES.auth.login, {
      replace: true,
    });
  }, []);

  const login = useCallback(
    async (username: string, password: string) => {
      try {
        const result = await signIn({ username, password }).unwrap();
        let isAuthenticated = true;
        let inProgress = false;
        if (result?.isMfaAuthConfigured || result?.isMfaAuthEnforced) {
          isAuthenticated = false;
          inProgress = true;
        }
        setLoginState({
          isAuthenticated,
          inProgress,
          error: null,
          isMfaAuthConfigured: result?.isMfaAuthConfigured,
          isMfaAuthEnforced: result?.isMfaAuthEnforced,
        });
      } catch (error) {
        setLoginState({
          ...loginState,
          isAuthenticated: false,
          inProgress: false,
          error: (error as FetchBaseQueryError)?.data as ErrorData,
        });
        showErrorMessage(ERR_MSG);
      }
    },
    [logout, loginState]
  );

  const verifyOtpCode = useCallback(
    async (code: string) => {
      try {
        await verifyMfaCode({ code }).unwrap();
        setLoginState({
          ...loginState,
          isAuthenticated: true,
          inProgress: false,
          error: null,
        });
      } catch (error) {
        setLoginState({
          ...loginState,
          isAuthenticated: false,
          inProgress: true,
          error: (error as FetchBaseQueryError)?.data as ErrorData,
        });
        processServerErrorResponse(error);
      }
    },
    [loginState]
  );

  const authContextValue = useMemo(
    () => ({
      isAuthenticated: loginState?.isAuthenticated,
      loginState,
      login,
      logout,
      verifyOtpCode,
      deleteMfa,
      createMfa,
      isFetching: isCurrentUserLoading,
      isLoading: isSignInLoading || isSignOutLoading || isVerifyMfaCodeLoading,
    }),
    [
      loginState,
      login,
      logout,
      verifyOtpCode,
      deleteMfa,
      createMfa,
      isSignInLoading,
      isSignOutLoading,
      isVerifyMfaCodeLoading,
      isCurrentUserLoading,
    ]
  );

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};
