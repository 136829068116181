import { FC, KeyboardEvent } from 'react';

import { Form, Formik } from 'formik';
import * as yup from 'yup';

import { Box, Button } from '@mui/material';

import FormikTextField from 'components/FormikTextField';
import ModalContentTitle from 'components/ModalContentTitle';
import { AuthParams } from 'types/auth.type';
import { Styles } from 'types/theme.type';

import LoginFormContainerWrapper from '../LoginFormContainerWrapper';

const styles: Styles = {
  loginForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    minWidth: '300px',
    p: 2,
  },
  textField: { maxWidth: '330px', width: '100%', height: '60px' },
  submitBtn: { width: 280 },
};

const loginFormInitialValues: AuthParams = {
  email: '',
  password: '',
};

const loginFormValidationSchema = yup.object().shape({
  email: yup.string().required("Обов'язкове поле").email('Введіть валідний email'),
  password: yup.string().required("Обов'язкове поле"),
});

const inputLabelProps = { shrink: true };

type Props = {
  disabled?: boolean;
  autoFocus?: boolean;
  onSubmit?: (values: AuthParams) => void;
};

const LoginForm: FC<Props> = ({ disabled = false, autoFocus = true, onSubmit = () => {} }) => {
  return (
    <LoginFormContainerWrapper>
      <Box pt={1}>
        <ModalContentTitle>Авторизація</ModalContentTitle>
        <Formik
          initialValues={loginFormInitialValues}
          onSubmit={onSubmit}
          validationSchema={loginFormValidationSchema}
        >
          {({ values, handleChange, submitForm }) => (
            <Form onKeyDown={(e: KeyboardEvent) => e.key === 'Enter' && submitForm()}>
              <Box sx={styles.loginForm}>
                <FormikTextField
                  id="email"
                  name="email"
                  label="Email"
                  size="small"
                  value={values.email}
                  onChange={handleChange}
                  autoFocus={autoFocus}
                  InputLabelProps={inputLabelProps}
                  sx={styles.textField}
                />
                <FormikTextField
                  id="password"
                  name="password"
                  label="Password"
                  size="small"
                  type="password"
                  value={values.password || ''}
                  onChange={handleChange}
                  autoComplete="new-password"
                  InputLabelProps={inputLabelProps}
                  sx={styles.textField}
                />
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disabled}
                  onClick={submitForm}
                  sx={styles.submitBtn}
                >
                  Авторизуватися
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </LoginFormContainerWrapper>
  );
};

export default LoginForm;
