export enum ApiErrorCodes {
  ERROR = 2000,
  MFA_REQUIRED = 2001,
  MFA_INVALID_CODE = 2002,

  INVALID_INPUT = 1001,
  INTERNAL_ERROR = 1006,
  PARSING_ERROR = 1007,
  TOO_LARGE = 1009,
  NOT_FOUND = 1011,
  LIMIT_EXCEEDED = 1012,
  NOT_IMPLEMENTED = 1013,
  ALREADY_EXISTS = 1014,
  LOCK_TIMEOUT = 1015,
  REQUEST_TIMEOUT = 1016,
  ACTION_NOT_ALLOWED = 1017,
  TOO_MANY_ANCHORS = 1018,
  LOCKED_FUNCTIONALITY = 1019,
  NOT_SUPPORTED_CONTEXT = 1024,
}
