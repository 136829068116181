import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { showErrorMessage } from 'components/Alert/utils';
import { ErrorData } from 'types/api.type';

const SOMETHING_WRONG_MESSAGE =
  'Сталась помилка при запиті до сервера :(! Спробуйте повторити запит через деякий час.';

export const parseExtraErrorValue = (key: string, value: unknown): string => {
  if (typeof value === 'string') {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map((v) => parseExtraErrorValue(key, v)).join('\n');
  }

  if (value !== null && typeof value === 'object') {
    return Object.entries(value)
      .map(([k, v]) => `${k}: ${parseExtraErrorValue(k, v)}`)
      .join('\n');
  }

  return SOMETHING_WRONG_MESSAGE;
};

export const processServerErrorResponse = (
  err: ErrorData | FetchBaseQueryError | unknown,
  defaultMessage?: string
): void => {
  console.error(err);
  const error = (
    'data' in (err as FetchBaseQueryError) ? (err as FetchBaseQueryError).data : err
  ) as ErrorData;
  if (!error?.reason) {
    showErrorMessage(defaultMessage || SOMETHING_WRONG_MESSAGE);
    return;
  }

  const { code, extra, reason } = error;
  const extraOptions = Object.keys(extra).length > 0;

  if (extraOptions) {
    Object.entries(extra).forEach(([key, value]) => {
      const formattedValue = parseExtraErrorValue(key, value);
      showErrorMessage(`${key}: ${formattedValue}`);
    });
  } else {
    showErrorMessage(`${code}: ${reason}`);
  }
};
