export const DEFAULT_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const DATE_TIME_SECOND_FORMAT = 'DD.MM.YYYY HH:mm:ss';

export const SEARCHES_DEFAULT_START_DATE = '2022-10-01';

export const DEFAULT_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
  timeZone: 'Europe/Kiev',
} as const;

export const DATE_SHORT_FORMAT: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
  timeZone: 'Europe/Kiev',
} as const;
