import { FC, useEffect, useState } from 'react';

import { Loading } from 'components/Loading';
import { redirectToLoginPage } from 'utils/auth';

type Error = Partial<{
  name: string;
  statusText: string;
  message: string;
  request: string;
}>;

type Props = {
  error?: Error;
};

const ErrorPage: FC<Props> = ({ error }) => {
  const [localError, setLocalError] = useState<Error | undefined>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    console.error(error);
    if (error?.name === 'ChunkLoadError') {
      setLoading(true);
      fetch(error.request as string, { method: 'HEAD', redirect: 'manual' })
        .then((response) => {
          if (
            (response.status === 401 || response.type === 'opaqueredirect') &&
            redirectToLoginPage()
          ) {
            return;
          }
          setLocalError(error);
        })
        .catch(() => setLocalError(error))
        .finally(() => setLoading(false));
      return;
    }
    setLocalError(error);
  }, [error]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      {!!localError && (
        <p>
          <i>{localError.statusText}</i>
          <ul>
            <li>
              <i>{localError.message}</i>
            </li>
          </ul>
        </p>
      )}
    </div>
  );
};

export default ErrorPage;
