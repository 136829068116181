import { createContext } from 'react';

import { AuthContextType } from './types';

export const AuthContext = createContext<AuthContextType>({
  isLoading: false,
  isFetching: false,
  isAuthenticated: false,
  login: async () => {},
  logout: async () => {},
  verifyOtpCode: async () => {},
  createMfa: async () => ({ otpAuthUrl: '', otpAuthSecret: '' }),
  deleteMfa: async () => {},
});
