import { FC } from 'react';

import { Box, Button } from '@mui/material';

const styles = {
  cancelButtonBox: {
    textAlign: 'left',
    pb: 4,
  },
  cancelButton: {
    pt: 0,
    pb: 0,
  },
};

type Props = {
  onClick: () => void;
};

const CancelButton: FC<Props> = ({ onClick }) => {
  return (
    <Box sx={styles.cancelButtonBox}>
      <Button
        sx={styles.cancelButton}
        color="error"
        variant="text"
        disabled={false}
        onClick={onClick}
      >
        Скасувати
      </Button>
    </Box>
  );
};

export default CancelButton;
