import { createBrowserRouter } from 'react-router-dom';

import { PUBLIC_ROUTES } from 'constants/publicRoutes';
import Login from 'pages/Login';
import Logout from 'pages/Logout';

export const router = createBrowserRouter([
  {
    path: PUBLIC_ROUTES.auth.login,
    element: <Login />,
  },
  {
    path: PUBLIC_ROUTES.auth.logout,
    element: <Logout />,
  },
  {
    path: '*',
    element: <Login />,
  },
]);
