import { LOGIN_PAGE_URL } from 'constants/auth';

export const redirectToLoginPage = () => {
  const pathname = window.location.pathname.toLowerCase();
  const loginPageUrl = LOGIN_PAGE_URL.toLowerCase();
  if (pathname.includes(loginPageUrl)) {
    return false;
  }

  let redirectTo = '';
  if (pathname !== '/') {
    redirectTo = `?redirectTo=${window.location.pathname}`;
  }
  window.location.href = `${LOGIN_PAGE_URL}${redirectTo}`;
  return true;
};
