import { endpoints } from 'api/endpoints/auth';
import { api } from 'applications/auth/api';
import { APP_BE_URL } from 'constants/appBeUrl';
import { CurrentUser } from 'types/account.type';

const accountUrl = `${APP_BE_URL}/api/v1/account`;

const authApi = api.injectEndpoints({
  endpoints,
});

const accountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<CurrentUser, void>({
      query: () => `${accountUrl}/current_user`,
      providesTags: ['currentUser'],
    }),
  }),
});

export const {
  useGetMfaStatusQuery,
  useGetMfaRecoveryCodesQuery,
  useSignInMutation,
  useSignOutMutation,
  useVerifyMfaCodeMutation,
  useCreateMfaMutation,
  useDeleteMfaMutation,
} = authApi;

export const { useGetCurrentUserQuery } = accountApi;
