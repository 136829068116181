import { PropsWithChildren } from 'react';

import { Typography } from '@mui/material';

const styles = {
  headerBox: {
    pb: 1.5,
    fontWeight: 700,
    fontSize: '32px',
  },
};

const ModalContentTitle = ({ children }: PropsWithChildren) => {
  return (
    <Typography sx={styles.headerBox} variant="h4">
      {children}
    </Typography>
  );
};

export default ModalContentTitle;
