import { toast, ToastContent } from 'react-toastify';

export const showSuccessMessage = (msg: string) =>
  toast.success(msg, {
    className: 'success-toaster',
    position: 'bottom-left',
  });

export const showWarningMessage = (msg: ToastContent, options?: { closeOnClick?: boolean }) =>
  toast.warning(msg, {
    className: 'warning-toaster',
    position: 'bottom-left',
    theme: 'colored',
    closeOnClick: options?.closeOnClick,
  });

export const showErrorMessage = (msg: string) =>
  toast.error(msg, {
    className: 'error-toaster',
    position: 'bottom-left',
    theme: 'colored',
  });
